
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import TableForm from '@/components/table/TableForm.vue';
import { namespace } from 'vuex-class';
import EditTableLoader from '@/components/table/EditTableLoader.vue';
import Notification from '@/mixins/Notification';
import { Printer } from '@/interfaces/models/Printer';
import EditPrinterLoader from '@/components/printer/EditPrinterLoader.vue';
import { Article } from '@/interfaces/models/Article';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { Venue } from '@/interfaces/models/Venue';
import { Category } from '@/interfaces/models/Category';
import PrinterForm from '@/components/printer/PrinterForm.vue';
import StackedForm from '@/mixins/StackedForm';

const printer = namespace('printer');
const venue = namespace('venue');

@Component({
  components: { PrinterForm, EditPrinterLoader, EditTableLoader, TableForm, VWrapper },
})
export default class EditPrinter extends mixins(Editable, Notification, StackedForm) {
  @Prop({ type: Object, required: true }) public item!: Printer;

  @venue.State('active') public venue!: Venue;

  @printer.Action('update') public update!: any;

  public articles: Article[] = [];

  public $refs!: {
    form: InstanceType<typeof TableForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  public async mounted() {
    if (!this.item || !this.venue) {
      this.$router.push({ name: 'printer.index' });
    }
    this.$startLoading('article');
    const api: FoodcardApiService = new FoodcardApiService();
    const data: Category[] = (await api.getCategoriesByVenue({ id: this.venue._id })).data;
    for (const cat of data) {
      this.articles.push(...cat.articles);
    }
    this.$stopLoading('article');
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        await this.update({ venue: this.venue._id, printer: this.item.key, ...this.$refs.form.getData() });
        this.$router.push({ name: 'printer.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
