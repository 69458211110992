
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Article } from '@/interfaces/models/Article';

@Component({
  components: { VFormBuilder },
})
export default class PrinterForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true }) public articles!: Article[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'name', type: InputType.Text, label: 'printer.form.name', rules: 'required' },
      {
        name: 'articles',
        type: InputType.Select,
        multiple: true,
        rules: 'required',
        label: 'printer.form.articles',
        items: this.articleItems,
      },
    ];
  }

  get articleItems() {
    return this.articles.map((a: Article) => {
      return {
        text: this.$options.filters!.localized(a.name),
        value: a._id,
      };
    });
  }

  public getData() {
    return { ...this.$refs.form.form };
  }
}
